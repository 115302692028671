<!-- 用户订单详情 -->
<template>
  <div class="ucenter">
    <el-row>
      <el-col :span="4" class="sider">
        <div
          style="
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-weight: bold;
            color: #ff414d;
          "
        >
          会员首页
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <div class="collapse-item__title">交易中心</div>
            </template>
            <ul class="collapse-item__ul">
              <li
                v-for="(item, k) in tradeMenu"
                :key="k"
                @click="changeUrl(item)"
              >
                <span v-if="item.id === activeId" style="color: #ff414d">
                  {{ item.title }}
                </span>
                <span v-else>
                  {{ item.title }}
                </span>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col
        :span="20"
        style="padding: 0 20px"
        v-loading="loading"
        element-loading-text="加载中"
      >
        <!-- 订单状态 -->
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-size: 16px">订单状态</span>
          </div>
          <div id="orderButton">
            <div>
              该订单会为您保留30天（从下单之日算起），30天之后如果还未付款，系统将自动取消该订单。
            </div>
            <div class="btn" v-if="parseInt(orderInfo.pay_status) <= 0">
              <el-button type="danger" size="mini" @click="payOrder">立即支付</el-button>
            </div>
            <div class="btn" v-if="parseInt(orderInfo.pay_status) <= 0">
              <el-button type="danger" size="mini" @click="cancleOrder(orderInfo.order_no)">取消订单</el-button>
            </div>
            <div
              class="btn"
              v-if="
                orderInfo.refund_service_status < 3 &&
                orderInfo.pay_status == 1 &&
                orderInfo.refund_status == 0
              "
            >
              <el-button type="danger" size="mini" @click="refundOrder">申请退款</el-button>
            </div>
            <div
              class="btn"
              v-if="
                orderInfo.pay_status == 1 &&
                orderInfo.refund_status == 1 &&
                orderInfo.finish_status == 0
              "
            >
              <el-button type="danger" size="mini" @click="cancelRefund(orderInfo.order_no)">取消退款</el-button>
            </div>
          </div>
          <div class="orderStatus" v-if="orderInfo.refund_status < 1">
            <el-steps finish-status="success" :active="orderStatusValue">
              <el-step
                title="提交订单"
                icon="el-icon-tickets"
                :description="orderInfo.created_at"
              ></el-step>
              <el-step title="等待付款" icon="el-icon-money"></el-step>
              <el-step
                title="付款成功"
                icon="el-icon-sold-out"
                :description="
                  orderStatusValue === 3 ? orderInfo.updated_at : ''
                "
              ></el-step>
              <el-step
                title="正在配货"
                icon="el-icon-truck"
                :description="
                  orderStatusValue === 4 ? orderInfo.updated_at : ''
                "
              ></el-step>
              <el-step
                title="配货完成"
                icon="el-icon-finished"
                :description="
                  orderStatusValue === 5 ? orderInfo.updated_at : ''
                "
              ></el-step>
              <el-step
                title="订单完结"
                icon="el-icon-finished"
                :description="
                  orderStatusValue === 6 ? orderInfo.updated_at : ''
                "
              ></el-step>
            </el-steps>
          </div>
          <div class="orderStatus" v-else>
            <el-steps finish-status="success" :active="orderStatusValue">
              <el-step
                title="提交订单"
                icon="el-icon-tickets"
                :description="orderInfo.created_at"
              ></el-step>
              <el-step title="等待付款" icon="el-icon-money"></el-step>
              <el-step
                title="付款成功"
                icon="el-icon-sold-out"
                :description="
                  orderStatusValue === 3 ? orderInfo.updated_at : ''
                "
              ></el-step>
              <el-step
                title="申请退款"
                icon="el-icon-truck"
                :description="
                  orderStatusValue === 4 ? orderInfo.updated_at : ''
                "
              ></el-step>
              <el-step
                :title="orderRefundStatusText"
                icon="el-icon-finished"
                :description="
                  orderStatusValue === 5 ? orderInfo.updated_at : ''
                "
              ></el-step>
              <el-step
                title="订单完结"
                icon="el-icon-finished"
                :description="
                  orderStatusValue === 6 ? orderInfo.updated_at : ''
                "
              ></el-step>
            </el-steps>
          </div>
        </el-card>
        <!-- 快递信息 -->
        <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <span style="font-size: 16px">订单信息</span>
          </div>
          <div id="expressInfo">
            <el-row
              class="express"
              style="height: 40px; line-height: 40px; padding-top: 0"
            >
              <el-col :span="3" class="expressHeader">收货人信息</el-col>
              <el-col :span="3" class="expressData"
                >收货人：{{ orderInfo.name }}</el-col
              >
              <el-col :span="12" class="expressData"
                >地址：{{ orderInfo.address | addressToText }}</el-col
              >
              <el-col :span="6" class="expressData"
                >联系电话：{{ orderInfo.mobile }}</el-col
              >
            </el-row>
            <el-row class="express">
              <el-col :span="3" class="expressHeader">配送信息</el-col>
              <el-col :span="5" class="expressData"
                >配送方式：{{ orderInfo.express_company }}</el-col
              >
              <el-col :span="5" class="expressData">总重量：1100克</el-col>
              <el-col :span="11" class="expressData"
                >运费：{{ orderInfo.express_fee }}元</el-col
              >
            </el-row>
            <el-row class="express">
              <el-col :span="3" class="expressHeader">付款信息</el-col>
              <el-col :span="21" class="expressData"
                >付款方式：{{
                  orderInfo.gold > 0 ? "金币抵扣" : "全额支付"
                }}</el-col
              >
            </el-row>
            <el-row class="express" style="border-bottom: 0">
              <el-col :span="3" class="expressHeader">订单信息</el-col>
              <el-col :span="21" class="expressData"
                >订单号：{{ orderInfo.order_no }}</el-col
              >
            </el-row>
          </div>
        </el-card>
        <!-- 购买信息 -->
        <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <span style="font-size: 16px">购买详情</span>
          </div>
          <div>
            <el-table :data="goodsTableData" border style="width: 100%">
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image :src="scope.row.thumbnail"></el-image>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="商品"
                width="250"
              ></el-table-column>
              <el-table-column prop="color" label="规格"></el-table-column>
              <el-table-column prop="price" label="单价"></el-table-column>
              <el-table-column prop="weight" label="毛重"></el-table-column>
              <el-table-column prop="num" label="采购数"></el-table-column>
              <el-table-column label="发货数">
                <template slot-scope="scope">
                  {{ scope.row.done > 0 ? scope.row.num : 0 }}
                </template>
              </el-table-column>
              <el-table-column prop="total" label="小计"></el-table-column>
            </el-table>
            <el-row :gutter="20" style="text-align: right" id="payInfo">
              <el-col :span="21">商品总金额：</el-col
              ><el-col :span="3">{{ orderInfo.goods_total }}元</el-col>
              <el-col :span="21">代发服务费：</el-col
              ><el-col :span="3">{{ orderInfo.daifa_fee }}元</el-col>
              <el-col :span="21">高级质检：</el-col
              ><el-col :span="3">{{ orderInfo.check_fee }}元</el-col>
              <el-col :span="21">外包装费：</el-col
              ><el-col :span="3">{{ orderInfo.package_fee }}元</el-col>
              <el-col :span="21">运费：</el-col
              ><el-col :span="3">{{ orderInfo.express_fee }}元</el-col>
              <el-col :span="21">吊牌：</el-col
              ><el-col :span="3">{{ orderInfo.brand_fee }}元</el-col>
              <el-col :span="21">贺卡（好评卡）：</el-col
              ><el-col :span="3">{{ orderInfo.wishcard_fee }}元</el-col>
              <el-col :span="21">订单应付：</el-col
              ><el-col :span="3">{{ payTotal }}元</el-col>
              <el-col :span="21">金币抵扣：</el-col
              ><el-col :span="3">{{ orderInfo.gold }}元</el-col>
              <el-col :span="21">实际支付：</el-col
              ><el-col :span="3" style="font-size: 20px; color: #ff414d"
                >{{
                  parseFloat(payTotal - parseFloat(orderInfo.gold)).toFixed(2)
                }}元</el-col
              >
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getUcenterCollapseItems } from "@/util/common";
import { mapGetters } from "vuex";

export default {
  name: "OrderInfo",
  data() {
    return {
      order_no: "",
      activeName: "1",
      activeId: 1,
      tradeMenu: [],
      orderInfo: {},
      goodsTableData: [],
      loading: false,
    };
  },
  filters: {
    /**
     * 地址格式化
     * @param {*} address
     */
    addressToText(address) {
      if (address && address.province_text) {
        return (
          address.province_text +
          address.city_text +
          address.district_text +
          address.address
        );
      } else {
        return address;
      }
    },
  },
  computed: {
    ...mapGetters(["getUser"]),

    /**
     * 订单总价
     */
    payTotal: function () {
      const total =
        parseFloat(this.orderInfo.goods_total) +
        parseFloat(this.orderInfo.express_fee) +
        parseFloat(this.orderInfo.daifa_fee) +
        parseFloat(this.orderInfo.check_fee) +
        parseFloat(this.orderInfo.package_fee) +
        parseFloat(this.orderInfo.brand_fee) +
        parseFloat(this.orderInfo.wishcard_fee);
      return parseFloat(total).toFixed(2);
    },
    /**
     * 订单状态
     */
    orderStatusValue: function () {
      if (parseInt(this.orderInfo.finish_status) > 0) return 6;
      if (parseInt(this.orderInfo.done_status) > 0) return 5;
      if (
        parseInt(this.orderInfo.express_status) > 0 ||
        parseInt(this.orderInfo.refund_status) > 0
      )
        return 4;
      if (parseInt(this.orderInfo.pay_status) > 0) return 3;
      return 2;
    },
    /**
     * 退款状态文本
     */
    orderRefundStatusText: function () {
      return parseInt(this.orderInfo.refund_service_status) === 5
        ? "退款成功"
        : "退款中";
    },
  },
  created() {
    if (this.$route.query) {
      this.order_no = this.$route.query.order_no;
      this.getOrderInfo();
    }
    this.tradeMenu = getUcenterCollapseItems();
  },
  methods: {
    /**
     * 切换链接
     * @param {*} item
     */
    changeUrl(item) {
      if (item.url) {
        this.activeId = item.id;
        this.$router.push(item.url);
      }
    },
    /**
     * 获取商品信息
     */
    async getOrderInfo() {
      const { data } = await this.$axios.get(
        this.$apiBaseUrl + "/api/6246b9c0899d5?order_no=" + this.order_no
      );
      if (data.code === 1) {
        this.orderInfo = data.data;
        const goods = data.data.goods_list;
        let goodsSpecList = [];
        for (let i in goods) {
          const specs = goods[i].goods_specs;
          for (let j in specs) {
            goodsSpecList.push({
              name: goods[i].goods_name,
              thumbnail: specs[j].thumbnail,
              color: specs[j].color,
              price: specs[j].price,
              weight: specs[j].weight,
              num: specs[j].goods_num,
              total: parseFloat(
                parseInt(specs[j].goods_num) * parseFloat(specs[j].price)
              ).toFixed(2),
              done: parseInt(specs[j].done_status),
            });
          }
        }
        this.goodsTableData = goodsSpecList;
      }
    },
    /**
     * 订单支付跳转
     * @param {*} order
     */
    async payOrder(order) {
      const payTotal = parseFloat(
        parseFloat(order.daifa_fee) +
          parseFloat(order.check_fee) +
          parseFloat(order.package_fee) +
          parseFloat(order.brand_fee) +
          parseFloat(order.wishcard_fee) +
          parseFloat(order.express_fee) +
          parseFloat(order.goods_total) -
          parseFloat(order.gold)
      ).toFixed(2);
      if (payTotal === 0.0) {
        // 全金币支付
        let response = await this.$axios.get(
          this.$apiBaseUrl + "/api/6329752b4c446?order_no=" + order.order_no
        );
        if (response.data.code === 1) {
          this.notifySucceed("支付成功，即将跳转我的订单页面!");
        } else {
          this.notifyError(response.data.msg);
        }
      } else {
        window.location.href =
          "https://apicenter.toysmodel.cn/api/6246c2e832768?web=1&order_no=" +
          order.order_no;
      }
    },
    /**
     * 申请退单
     */
    async refundOrder() {
      await this.$axios.post(this.$newApi + "/api/rbh/order/refund", {
        uid: this.$store.getters.getUser.id,
        order_no: this.order_no,
        reason: "申请退单",
      });
      this.notifySucceed(
        this.form.order_express_no
          ? "您的退货退款申请已发送给代发商家，请耐心等待审核!"
          : "您的退款申请已发送给代发商家，请耐心等待审核!"
      );
      this.dialogReasonVisible = false;
    },
    /**
     * 取消订单
     * @param {*} order_no
     */
    async cancleOrder(order_no) {
      await this.$axios.get(
        this.$apiBaseUrl + "/api/62511460d3358?order_no=" + order_no
      );
      this.notifySucceed("订单取消成功!");
    },
    /**
     * 取消退款
     * @param {*} order_no
     */
    async cancelRefund(order_no) {
      await this.$axios.get(
        this.$newApi + "/api/v2/order/resetRefund?order_no=" + order_no
      );
      this.notifySucceed("取消退款成功!");
    }
  },
};
</script>

<style scoped>
.ucenter {
  width: 1225px;
  font-size: 14px;
  margin: 0 auto;
}
.collapse-item__title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
}
.collapse-item__ul {
  padding: 5px 0 0 0;
}
.collapse-item__ul li {
  color: #757578;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 1em;
  cursor: pointer;
}

#orderButton {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #8c8c8e;
}

#orderButton .btn {
  margin: 0 5px;
  position: relative;
  top: -5px;
}

.orderStatus {
  font-size: 14px;
  color: #8c8c8e;
  margin-top: 10px;
}

#expressInfo {
  font-size: 12px;
  color: #757578;
}

#expressInfo .express {
  border-bottom: 1px dashed #e5e5e5;
  height: 40px;
  line-height: 40px;
  padding-top: 15px;
}

.expressHeader {
  border-left: 2px solid #f03a3c;
  color: #3b3e44;
  padding-left: 5px;
  height: 11px;
  line-height: 11px;
  font-weight: bold;
}

.expressData {
  height: 11px;
  line-height: 11px;
}

#payInfo {
  font-size: 14px;
  color: #757578;
  padding: 10px 0;
}

#payInfo .el-col-21,
#payInfo .el-col-3 {
  line-height: 25px;
  height: 25px;
}
</style>
